<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent
        :button="drawerButton"
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        @save="requestSave"></DrawerComponent>
      <!-- 弹窗商品列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="添加商品"
          size="80%"
          :visible.sync="drawerShowProduct"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchProduct"
                            :pagination="tablePaginationProduct"
                            :templateData="tableDataProduct"
                            :table-loading="tableLoadingProduct"
                            :searchConfig="searchConfigProduct"
                            :table-config="tableConfigProduct"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowProduct = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>

    <DrawerComponent
      v-if="dmsId"
      :button="drawerButton"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      @save="requestSave"></DrawerComponent>
  </div>

</template>

<script>
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  props: ['dmsId', 'dmsCode'],
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      orderCode: '',
      loading: false,
      code: 'add',
      id: '',
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: (data) => {
              this.requestSave(data, 'Y');
            },
          },
        ],
      },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { temporaryFlag: 'N' },
          formConfig: { // 弹窗表单配置
            disabled: false,
            labelWidth: '160px',
            items: [
              [{
                label: '单据编号',
                key: 'weekPlanCode',
                disabled: true,
                show: false,
              }, {
                label: '负责人',
                key: 'createName',
                disabled: true,
              }, {
                label: '所属组织',
                key: 'createOrgName',
                disabled: true,
              }, {
                label: '开始时间',
                key: 'beginDate',
                valueType: 'date',
              }, {
                label: '结束时间',
                key: 'endDate',
                valueType: 'date',
              }, {
                label: '上报日期',
                key: 'reportDate',
                valueType: 'date',
              }],
            ],
          },
          rules: {
            reportDate: [{ required: true, message: '请选择上报日期', trigger: 'change' }],
            endDate: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
            beginDate: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
          },
        }, {
          title: '商品信息',
          type: 'table',
          tableConfig: {
            disabled: false,
            rows: [
              {
                label: '商品编码',
                prop: 'productCode',
              }, {
                label: '商品名称',
                prop: 'productName',
              }, {
                label: '规格',
                prop: 'productStandard',
              }, {
                label: '香型',
                prop: 'fragranceType',
              }, {
                label: '数量(盒)',
                prop: 'num',
                type: 'input',
              }, {
                label: '计划时间',
                prop: 'planDate',
                type: 'input',
              }, {
                label: '备注',
                prop: 'remarks',
                type: 'input',
              },
            ],
            rowButtons: {
              items: [{
                type: 'default',
                icon: 'el-icon-delete',
                fn: (row, index) => {
                  this.deleteProduct(index, 1);
                },
              }],
            },
          },
          tableData: [],
          tableButtons: [
            {
              title: '添加商品',
              fn: () => {
                this.searchProduct({});
              },
            }, {
              title: '清空',
              fn: () => {
                this.deleteProduct('all', 1);
              },
            },
          ],
        },
      ],

      //  ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [],
      tableConfigProduct: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '商品编码',
            prop: 'materialCode',
          },
          {
            label: '商品名称',
            prop: 'materialName',
          },
          {
            label: '规格',
            prop: 'specification',
          }, {
            label: '单位',
            prop: 'ext3',
          }, {
            label: '香型',
            prop: 'ext2',
          },
        ],
      }, // 列表配置
      searchConfigProduct: {
        options: [
          {
            valueName: 'materialCode',
            placeholder: '请输入商品编号',
          }, {
            valueName: 'materialName',
            placeholder: '请输入商品名称',
          },
        ],
      },
      tablePaginationProduct: {},
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },

  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },

  methods: {

    initPageState() {
      if (this.code !== 'add') { // 非新增查询详情
        this.setDrawerValue('基本信息', 'show', true);
        this.searchDetail();
      } else {
        this.setFormData(this.userInfo.fullName, 'createName');
        this.setFormData(this.userInfo.orgName, 'createOrgName');
        this.setDisabled(false);
      }
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.$nextTick(() => {
        this.drawerConfig[0].formConfig.disabled = state;
        this.drawerConfig[1].tableConfig.disabled = state;
        this.drawerConfig[1].tableConfig.rowButtons.show = !state;
        this.drawerButton.show = !state;
      });
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post('/dms/dms/weekdemandplan/query', { id: this.id });
        this.drawerConfig[0].ruleForm = { ...result };
        this.drawerConfig[1].tableData = result.detailList;
        this.setDisabled(this.drawerTitle === '查看');
      }
    },

    /**
     * 查询商品列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      this.tableLoadingProduct = true;
      const params = {
        pageSize: 10,
        ...requestData,
        isShelf: 'Y',
      };
      const { result: { data, count } } = await this.$http.post(
        '/mdm/mdmMaterialExtController/pageList', params,
      );
      this.tableDataProduct = data;
      // this.tableDataProduct = data.map((i) => {
      //   // eslint-disable-next-line no-param-reassign
      //   i = { ...i, ...i.materialList[0] };
      //   return i;
      // });
      this.tableLoadingProduct = false;
      this.tablePaginationProduct.total = count;
      if (!this.drawerShowProduct) {
        this.showDrawer('drawerShowProduct');
      }
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      const obj = {};
      const selectTableData = [...this.selectTableData, ...data];
      this.selectTableData = selectTableData.reduce((item, next) => {
        if (!obj[next.id]) {
          item.push(next);
          obj[next.id] = true;
        }
        return item;
      }, []);
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowProduct === true) {
        this.saveProduct();
        return true;
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存选中的商品
     */
    saveProduct() {
      this.selectTableData.map((i) => {
        delete i.id;
        i.fragranceType = i.ext2;
        i.productCode = i.materialCode;
        i.productName = i.materialName;
        i.productStandard = i.specification;
        i.saleUnitName = i.baseUnitName;
        i.remarks = '';
        return i;
      });
      this.selectTableData = [...this.drawerConfig[1].tableData, ...this.selectTableData];
      this.setDrawerValue('商品信息', 'tableData', this.selectTableData);
      this.closeDrawer('drawerShowProduct');
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, temporaryFlag) {
      data.detailList = this.drawerConfig[1].tableData;
      data.temporaryFlag = temporaryFlag || 'N'; // 是否暂存
      this.loading = true;
      const url = this.code === 'edit' ? '/dms/dms/weekdemandplan/updateWeekDemandPlan' : '/dms/dms/weekdemandplan/saveWeekDemandPlan';
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 删除商品
     * @param index {number || String}
     * @param configIndex {number}
     */
    deleteProduct(index, configIndex) {
      if (index === 'all') {
        this.drawerConfig[configIndex].tableData = [];
      } else {
        this.drawerConfig[configIndex].tableData.splice(index, 1);
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this.selectTableData = [];
      this[name] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button{
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #EBEEF5;
}
.drawer-table {
  padding:20px;
  margin-bottom: 100px;
}
.slot-body{
  display: flex;
  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span{
      flex-shrink: 0;
      margin-right: 5px;
    }
    input{
      width: 150px;
    }
  }
}
</style>
